/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import '../node_modules/primeng/resources/themes/nova-light/theme.css';
// @import '../node_modules/primeng/resources/primeng.min.css';
//variables 


.body {
  font-family: IBM Plex Sans !important;
  font-size: 14px;
}

.text-red {
  color: red;
}
.text-green {
  color: green;
}

.text-blue {
  color: #0084ff;
}

.pd-10 {
  padding: 10px;
}

.required-indicator {
  color: red;
  padding: 2px;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.vertical-dotted-border {
  box-shadow: none;
  border-right: 1px dotted;
  height: 100%;
}

.horizontal-dotted-border {
  padding-top: 1em;
  box-shadow: none;
  border-top: 1px dotted;
  width: 100%;
}

.pull-right {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.tabCardHead {
  padding: 3px 10px;
  border-radius: 4px 4px 0 0;
}

.tabCardHead a {
  color: #000 !important;
}

.hide-context-menu .ui-contextmenu {
  visibility: hidden !important;
}

.padTop .ui-panel-content-wrapper .ui-panel-content {
  padding-top: 0 !important;
}

.padNone .ui-panel-content-wrapper .ui-panel-content {
  padding: 0 !important;
}

.short-text {
  display: none;
}

@media (max-width: 1410px) {
  .short-text {
    display: inline-block;
  }
  .full-text {
    display: none;
  }
}
.notesExpanded {
  text-align: center;
  line-height: 0px !important;
  padding: 1% !important;
  cursor: pointer;
  background-color: lightgray !important;
}
.tablestriped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #f2f2f2;
}
.tablestriped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: white;
}

.ui-dialog-content{
  .ui-custom-card{
      .ui-table{
          margin-bottom: 0 !important;
      }
      margin-top: 10px;
  }
  .ui-custom-card.table-content{
    padding:10px;
  }
}
// .custom-data-table {
//   th {
//       height: 80px;
//       width: 205px;
//   }
//   td {
//       width: 205px;
//       height: 200px;
//   }
// }
// .ui-widget-header {
//   border: none !important;  
// }

.custom-data-table {
  th {
      color: white !important;
      font-size: 16px !important;
      font-weight: bold !important;
      text-align: center !important ;
      height: 40px;
      width: 300px;
      border: none !important;
      border-left: 4px solid white !important;
      padding: 0 !important;
  }
  td {
      width: 300px;
      min-height: 50px;
      border: none !important;
      border-left: 4px solid white !important;
      padding: 0 !important;
  }
}

.custom-data-table-payment {
  td {
      width: 180px;
      line-height: 1.5;
      //min-height: 50px;
      border: none !important;
      border-left: 2px solid #ffffff !important;
      padding: 0 !important;
  }
}
.ui-widget-header {
  border: none !important;  
}

.ui-dialog-titlebar-icons {
  margin-left: auto;
  margin-bottom: 5px;
}

.client-data {
  text-align: center;
  background: white;
  height: 150px;
  width: 95%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:4px;
  margin-bottom:4px;
  border: 1px solid #dfe5e7 !important;
  a {
    font-size: 12px;
    font-weight: 600;
  }
}

.custom-data-table .client-data p{margin:0}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 12px solid;
  border-bottom: 8px solid transparent;
  float: right;
  position: relative;
  left: 26px;
  top:4px;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 14px solid;
  border-bottom: 10px solid transparent;
  float: right;
  position: relative;
  left: 18px;
  top: 2px;
  border-left-color: white;
}
.client-products {
  overflow-y: auto;
  overflow-x: hidden;
  height: 55%;
  span {
    padding: 5px;
  }
}
.modal_in {
  .fa-close{
    &:before {
      content:"close" !important;
      color: black;
    }
  }
}
.global-styling {
  margin-bottom: 42px !important;
}
.no-decor {
  font-size: 42px !important;
  font-family: IBM Plex Sans;
  color: #000 !important;
}

body p-fullCalendar .ui-state-active, body p-fullCalendar .ui-state-highlight {
    background-color: transparent !important;
    color: #212121 !important;
  }
  .ui-table-resizable>.ui-table-wrapper {
    width: 100%;
    th {
      white-space: nowrap !important;
    }
  }

  .layout-wrapper .header-logo .header-anchor {
    width: 18px;
    height: 18px;
    border: 2px solid #ffffff;
    background-color: #ccc;
    vertical-align: middle;
    // float: right;
    // margin-right: 8px;
    // margin-top: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.layout-wrapper.layout-wrapper-static .header-logo .header-anchor {
  display: inline-block !important;
  background-color: #ffffff !important;
}

.layout-wrapper, .layout-main {
  height: 100% !important;
}
.layout-wrapper .layout-main .layout-content {
  height: calc(100% - 106px) !important;
}


.ui-calendar-w-btn {
  display: flex;
  align-items: center;
  .ui-calendar-button {
    position: absolute;
    border: none;
    bottom: auto !important;
  }
}
.ui-dropdown-trigger {
  height: 100% !important;
}
.ui-autocomplete-dropdown {
  border-radius: 0 !important;
  height: 100% !important;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  background-color: #659ee9;
}

.mat-form-field-infix{
  border-top: none !important;
}

.cs-dialog {
  .ui-dialog .ui-dialog-titlebar-icon {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ui-widget-overlay {
    // background-color: rgba(0,0,0,.4);
    opacity: unset;
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
  }
}

:host ::ng-deep .ui-dialog-mask.ui-widget-overlay{
  opacity: 1 !important;
  z-index: 1000 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.cdk-overlay-container {
  z-index: 1100 !important;
}

.cs-dialog .ui-widget-overlay{
  z-index: 9999999999!important;
}
.ui-table-wrapper {
  z-index: 9999;
    overflow: visible;
    overflow-x: auto !important;
}

.btn-custom-maticon {
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  mat-icon {
    position: absolute;
    transform: translate(-50%, 15%);
  }
}
.ngx-mat-timepicker {
  margin-bottom: -25px !important;
}

.btn-border {
  border: 1px solid #d8d8d8;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-label{
  position: absolute;
  font-weight: bold;
  padding: 0 3px 0 3px;
  top: 60%;
  transform: translateX(38%);
  &.red{
    background-color: red;
  }
  &.burlyWood{
    background-color: burlyWood;
  }
  &.crimson{
    background-color: crimson;
  }
  &.white{
    background-color: white;
  }
  &.darkCyan{
    background-color: darkCyan;
  }
  &.darkGray{
    background-color: darkGray;
  }
  &.darkOrange{
    background-color: darkOrange;
  }
  &.darkRed{
    background-color: darkRed;
  }
  &.DarkSlateGray{
    background-color: DarkSlateGray;
  }
  &.chartreuse{
    background-color: chartreuse;
  }
}
.marker-label-1 {
  position: relative;
  font-weight: bold;
  padding: 0 3px 0 3px;
  top: 30% !important;
  right: 30% !important;
  transform: translateX(38%);
}

.marker-label-2 {
  position: relative;
  font-weight: bold;
  padding: 0 3px 0 3px;
  top: -20% !important;
  right: 30% !important;
  transform: translateX(38%);
}

.marker-label-custom{
  font-weight: bold;
	padding: 2px 4px;
  &.red{
    background-color: red;
  }
  &.burlyWood{
    background-color: burlyWood;
  }
  &.crimson{
    background-color: crimson;
  }
  &.white{
    background-color: white;
  }
  &.darkCyan{
    background-color: darkCyan;
  }
  &.darkGray{
    background-color: darkGray;
  }
  &.darkOrange{
    background-color: darkOrange;
  }
  &.darkRed{
    background-color: darkRed;
  }
  &.DarkSlateGray{
    background-color: DarkSlateGray;
  }
  &.chartreuse{
    background-color: chartreuse;
  }
}

.card-management-modal .ui-dialog-titlebar{
  display: flex;
}

.ui-paginator .ui-dropdown {
  width: 5em !important;
  max-width: 6em;
}
label {
  display: block !important;
}
.mat-form-field label {
  margin-top: -15px !important;
}
// .mat-form-field-wrapper {
//   padding-bottom: unset !important;
// }

$liIndent: (
    "ql-indent-1": '\25CB',
    "ql-indent-2": '\25AA',
    "ql-indent-3": '\25AB',
    "ql-indent-4": '\25CF',
    "ql-indent-5": '\25CB',
    "ql-indent-6": '\25AA',
    "ql-indent-7": '\25AB',
    "ql-indent-8": '\25CF',
    "ql-indent-9": '\25CB',
    "ql-indent-10": '\25AA',
    "ql-indent-11": '\25AB',
);

@each $liIndent, $i in $liIndent {
  .#{$liIndent} {
    content: $i !important;
  }	
}


.ql-editor ul li.ql-indent-1::before {
  content: '\25CB' !important;
}
.ql-editor ul li.ql-indent-2::before {
  content: '\25AA' !important;
}
.ql-editor ul li.ql-indent-3::before {
  content: '\25AB' !important;
}
.ql-editor ul li.ql-indent-4::before {
  content: '\25CF' !important;
}
.ql-editor ul li.ql-indent-5::before {
  content: '\25CB' !important;
}
.ql-editor ul li.ql-indent-6::before {
  content: '\25AA' !important;
}
.ql-editor ul li.ql-indent-7::before {
  content: '\25AB' !important;
}
.ql-editor ul li.ql-indent-8::before {
  content: '\25CF' !important;
}
.ql-editor ul li.ql-indent-9::before {
  content: '\25CB' !important;
}
.ql-editor ul li.ql-indent-10::before {
  content: '\25AA' !important;
}
.ql-editor ul li.ql-indent-11::before {
  content: '\25AB' !important;
}


@-webkit-keyframes spin {
	0%  {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(360deg);}   
}
.loader {
	-webkit-animation: spin 4s infinite linear;
}
.one-liner {
	overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    max-height: 1.5rem;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
	display: -webkit-box;
	width: fit-content !important;
}
th {
    min-height: 45px !important;
	height: 45px !important;
}
.ui-table-wrapper {
	overflow: visible !important;
}
.layout-content {
	input[type="text"] {
		height: 40px !important;
		font-size: 16px !important;
	}
	input[type="password"] {
		height: 40px !important;
		font-size: 16px !important;
	}
	input[type="number"] {
		height: 40px !important;
		font-size: 16px !important;
	}
	input[type~="time"] {
		height: 40px !important;
		font-size: 16px !important;
	} 
	input[type="email"] {
		height: 40px !important;
		font-size: 16px !important;
	}
	input[type="url"] {
		height: 40px !important;
		font-size: 16px !important;
	}
	
	.ui-multiselect,
	.ui-dropdown, 
	.ui-dropdown .ui-dropdown-label {
		height: 40px !important;
		font-size: 16px !important;
		max-height: 40px !important;
	}
}
:host ::ng-deep .ui-menuitem-link:hover {
    background-color: #e8e8e8;
    color: #000000;
    text-decoration: none;
}
.ui-table-loading-content {
    color: #007eff;
    font-size: 30px;
}
:host ::ng-deep .ui-dropdown {
	height: 40px !important;
}
:host ::ng-deep .ui-dropdown {
	height: 40px !important;
}
:host ::ng-deep .ui-dropdown .ui-dropdown-label {
    height: 40px !important;
}
:host ::ng-deep .ui-dropdown .ui-dropdown-label.ui-inputtext {
    font-size: 16px !important;
}
.mat-form-field-flex {
    height: 40px !important;
    align-items: center !important;
    font-size: 16px !important;
    //padding: 0px 7px;
    display: flex !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 29px;
}
.ui-multiselect {
	height: 40px !important;
	max-height: 40px !important;
}
.mat-datepicker-content .mat-calendar {
    height: fit-content !important;
}
app-topbar .ui-inputgroup.pull-right i#right-icon-search {
	height: 30px !important;
    width: 30px !important;
    border: 0px !important;
    margin-top: 1px;
}
.cgap-5 {
	column-gap: 5px;
}
#button-addon2 {
    height: 40px !important;
}
.card-header, p-header {
	font-weight: 500 !important;
}
.nav-link {
	color: #666;
}
.nav-link.active {
	color: #000;
}
.nav-tabs {
  border-bottom: 0px !important;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-right: 3px;
    border-bottom: 0px !important;
    
}
td:last-of-type {
	
}
.rgap {
	row-gap: 20px;
}
.cgap {
	column-gap: 10px;
}
.icongap {
	column-gap: 5px;
	row-gap: 5px;
}
tr {
    min-height: 45px !important;
}
.one-liner-h1 {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
    max-height: 2rem;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.round {
	border-radius: 50% !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
    color: #7f7f7f;
	height: 40px !important;
}
.ui-dropdown.ui-widget {
    display: flex !important;
    width: inherit;
}
:host ::ng-deep p-table p-paginator .ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header.ui-unselectable-text.ui-helper-clearfix.ng-star-inserted {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding-top: 20px;
}
:host ::ng-deep input.mat-input-element {
    padding-left: 7px !important;
}
/* New Stuff */
#sortControls .ui-overlaypanel-content {
    padding: 0px !important;
}
.ui-password-panel {
	background: #fff !important;
	box-shadow: 0px 2px 10px -6px !important;
	font-size: 12px !important;
}
.ui-multiselect-filter-icon.pi.pi-search {
	display: none !important;
}
.ui-multiselect-panel a.ui-multiselect-close.ui-corner-all {
    padding-left: 5px;
}
.main-component.p-1 {
	color: #fff !important;
}
.fc-daygrid table.fc-scrollgrid-sync-table {
    width: 100% !important;
}
.fc-daygrid.fc-dayGridMonth-view.fc-view {
	background-color: #fff;
}

.fc-scrollgrid-section th[role="presentation"] {
    padding: 0px !important;
}
thead tr.fc-scrollgrid-section.fc-scrollgrid-section-header th {
	padding: 0px !important;
}
:host ::ng-deep .fc-daygrid-body.fc-daygrid-body-unbalanced {
	width: 100% !important;
}
.p-frozen-column {
  position: sticky;
  background: inherit;
}

th.p-frozen-column {
  z-index: 1;
}

*:not([type="text"]):not([type="password"]):not([type="number"]):not([type="url"]):not(textarea):not(.ql-editor):not(p-editor p):not(.mat-input-element):not(.ql-editor.ql-blank::before)  {
    caret-color: transparent;
}
[type="text"], [type="password"], [type="number"], textarea, p-editor, .ql-editor p, .ui-editor-content.ql-container.ql-snow p {
	caret-color: #666 !important;
}

.ui-tooltip {
	max-width: unset !important;
}
.p-overflow-hidden {
	overflow: auto !important; 
}

@keyframes colorChange {
	0% { color: #ff2323; }
	50% { color: #ffbb00; }
	100% { color: #ff2323; }
  }
  
  @keyframes backgroundChange {
	0% { background: #ff2323; }
	50% { background: #ffbb00; }
	100% { background: #ff2323; }
  }
  

  .loop-color {
	animation: colorChange 0.5s infinite;
  }
  .loop-background {
	animation: backgroundChange 0.5s infinite;
  }
  :host ::ng-deep div#js-licensing {
    display: none !important;
	visibility: hidden;
}


div[style^="position:fixed;top:0;left:0;right:0;"] {
	display: none !important;
	visibility: hidden;
}

.custom-popver {
  .popover-body {
    padding: 0;
  }
  &.popover {
    max-width: 400px !important;
    border-radius: 15px;
    padding: 0;
  }
}